import { createRouter, createWebHistory } from 'vue-router';


const routes = [
    {
        path: "/",
        redirect: "/home",
        component: () => import("@/view/Main"),
        children: [
           
            {
                path: "/",
                name: "ged",
                component: () => import("@/view/components/Ged"),

            },
            {
                path: "/",
                name: "Servicos",
                component: () => import("@/view/components/Servicos"),

            },
            {
                path: "/",
                name: "equipe-medica",
                component: () => import("@/view/components/EquipeMedica"),

            },

            
            {
                path: "/",
                name: "Solucoes",
                component: () => import("@/view/components/Solucoes"),

            },

            
        ]
    },
  ];
  
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  export default router; // Certifique-se de exportar o router